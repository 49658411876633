const _adminnav = [
  {
    _tag: "CSidebarNavTitle",
    _children: ["Admin Tasks"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Master List Directory",
    to: "/master-list-directory",
    icon: "cil-list",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Voting Sheet Review",
    to: "/voting-sheet-review",
    icon: "cil-find-in-page",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Job Assignment Schedule",
    to: "/job-assignment-schedule",
    icon: "cil-clipboard",
  },
  {
    _tag: "CSidebarNavItem",
    name: "User Management",
    to: "/user-management",
    icon: "cil-user",
  },
];

export default _adminnav;
