import {
  cilList,
  cilLockLocked,
  cilPrint,
  cilUser,
  cilClipboard,
  cilBalanceScale,
  cilFindInPage,
  cilChart,
  cilAccountLogout,
  cilHistory,
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    cilHistory,
    cilAccountLogout,
    cilFindInPage,
    cilChart,
    cilBalanceScale,
    cilClipboard,
    cilList,
    cilLockLocked,
    cilPrint,
    cilUser,
  }
);
