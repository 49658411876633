import React, { useState } from "react";
import {
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

// sidebar nav config
import navigation from "./_nav";
import adminnavigation from "./_adminnav";
import authService from "../../components/api-authorization/AuthorizeService";

import SawbillLogo from "../../assets/SawbillLogo.png";

const TheSidebar = ({ sidebarShow, toggleSidebar }) => {
  const [isAdmin, setAdminStatus] = useState(false);
  const [isLoggedIn, setLoginStatus] = useState(false);

  authService.isAuthenticated().then(
    function (value) {
      if (value) {
        setLoginStatus(value);
        authService.isAdmin().then(function (admin) {
          setAdminStatus(admin);
        });
      }
    },
    function (error) {
      console.log("Error");
    }
  );

  return (
    <CSidebar show={sidebarShow} hideOnMobileClick onShowChange={toggleSidebar}>
      <button
        className="mobileMenuButton invis-btn float-right"
        onClick={() => toggleSidebar()}
      >
        <i className="fa fa-chevron-left fa-2x"></i>
      </button>
      <img className="sidebar-logo" src={SawbillLogo} alt="Sawbill Logo" />
      <CSidebarNav>
        {isLoggedIn && (
          <CCreateElement
            items={navigation}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        )}
        {isAdmin && (
          <CCreateElement
            items={adminnavigation}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        )}
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
