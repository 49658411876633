export const findIndexBefore = (element, array, start, end) => {
  start = start || 0;
  end = end || array.length;
  const pivot = parseInt(start + (end - start) / 2, 10);
  const arrayElement = array[pivot].jobName.toUpperCase();
  if (end - start === 1 && arrayElement < element) {
    return pivot;
  } else if (end - start === 1) {
    return pivot - 1;
  }
  if (end - start <= 1 || arrayElement === element) return pivot;
  if (arrayElement < element) {
    return findIndexBefore(element, array, pivot, end);
  } else {
    return findIndexBefore(element, array, start, pivot);
  }
};

export const sortJobs = (jobs) => {
  jobs.sort((a, b) => {
    a = a.jobName.toUpperCase();
    b = b.jobName.toUpperCase();
    if (a > b) {
      return 1;
    } else if (b > a) {
      return -1;
    } else {
      return 0;
    }
  });

  return jobs;
};

export const sortJobsPrefStoreDome = (jobs) => {
    jobs.sort((a, b) => {
        a = a.jobName.toUpperCase();
        b = b.jobName.toUpperCase();
        if (a === "STORE") {
            return -1;
        }
        if (b === "STORE") {
            return 1;
        }
        if (a === "DOME") {
            return -1;
        }
        if (b === "DOME") {
            return 1;
        }
        if (a > b) {
            return 1;
        } else if (b > a) {
            return -1;
        } else {
            return 0;
        }
    });

    return jobs;
};

export const filterJobs = (jobs) => {
  const filteredJobs = jobs.filter(
    (job) =>
      job.credits > 0 &&
      job.jobName.toUpperCase() !== "DOME" &&
      job.jobName.toUpperCase() !== "STORE"
  );

  return filteredJobs;
};
