import { combineReducers } from "redux";
import lists from "./listReducer";
import list from "./singleListReducer";
import jobs from "./jobReducer";
import jobList from "./jobListReducer";
import apiCallsInProgress from "./apiStatusReducer";

const rootReducer = combineReducers({
  lists,
  list,
  jobs,
  jobList,
  apiCallsInProgress,
});

export default rootReducer;
