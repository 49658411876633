import React from "react";
import { CHeader, CHeaderNav, CBreadcrumbRouter } from "@coreui/react";
import "font-awesome/css/font-awesome.min.css";

// routes config
import routes from "../routes";

import { TheHeaderDropdown } from "./index";

const TheHeader = ({ toggleSidebar }) => {
  return (
    <CHeader className="px-3 text-nowrap justify-content-between">
      <div className="d-inline-flex">
        <button className="invis-btn" onClick={() => toggleSidebar()}>
          <i className="fa fa-bars fa-2x align-self-center"></i>
        </button>

        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        />
      </div>
      <CHeaderNav className="px-3">
        <TheHeaderDropdown />
      </CHeaderNav>
    </CHeader>
  );
};

export default TheHeader;
