import React, { useState } from "react";
import { TheContent, TheSidebar, TheHeader } from "./index";

const TheLayout = () => {
  const [sidebarShow, setSidebarShow] = useState(window.innerWidth > 992);

  const handleToggleSidebar = () => {
    setSidebarShow(!sidebarShow);
  };

  return (
    <div className="c-app c-default-layout">
      <TheSidebar
        toggleSidebar={handleToggleSidebar}
        sidebarShow={sidebarShow}
      />
      <div className="c-wrapper">
        <TheHeader toggleSidebar={handleToggleSidebar} />
        <div className="c-body">
          <TheContent />
        </div>
      </div>
    </div>
  );
};

export default TheLayout;
