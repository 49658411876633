import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function listReducer(state = initialState.jobList, action) {
  switch (action.type) {
    case types.LOAD_JOB_LIST_SUCCESS:
      return action.jobList.map((job) => {
        return { value: job, label: job };
      });
    default:
      return state;
  }
}
