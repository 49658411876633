export const LOAD_JOBS_SUCCESS = "LOAD_JOBS_SUCCESS";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";

export const LOAD_LISTS_SUCCESS = "LOAD_LISTS_SUCCESS";
export const LOAD_LIST_SUCCESS = "LOAD_LIST_SUCCESS";
export const CREATE_LIST_SUCCESS = "CREATE_LIST_SUCCESS";
export const UPDATE_LIST_SUCCESS = "UPDATE_LIST_SUCCESS";

export const LOAD_JOB_LIST_SUCCESS = "LOAD_JOB_LIST_SUCCESS";

export const LOAD_PREFERENCE_HEADER_SUCCESS = "LOAD_PREFERENCE_HEADER_SUCCESS";

export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_LIST_SUCCESS = "DELETE_LIST_SUCCESS";

export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
