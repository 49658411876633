import React, { Suspense, useState, useEffect } from "react";
import { ApplicationPaths } from "../../components/api-authorization/ApiAuthorizationConstants";
import Loading from "../../components/common/Loading";
import { Redirect, Route, Switch } from "react-router-dom";
import { CContainer, CFade } from "@coreui/react";
import AuthorizeRoute from "../../components/api-authorization/AuthorizeRoute";
import authService from "../../components/api-authorization/AuthorizeService";

// routes config
import routes from "../routes";
import { Login } from "../../components/api-authorization/Login";
import { LoginActions } from "../../components/api-authorization/ApiAuthorizationConstants";

const ManageListDirectory = React.lazy(() =>
  import("../../components/master-list/list-directory/ManageListDirectory")
);

const ManageMasterList = React.lazy(() =>
  import("../../components/master-list/master-list/ManageMasterList")
);
const ManageJobForm = React.lazy(() =>
  import("../../components/master-list/job-form/ManageJobForm")
);

const JobPreferenceSelection = React.lazy(() =>
  import("../../components/job-preference-selection/JobPreferenceSelection")
);
const JobPreferenceReview = React.lazy(() =>
  import("../../components/job-preference-review/JobPreferenceReview")
);

const ManageJobAssignment = React.lazy(() =>
  import("../../components/job-assignment/ManageJobAssignment")
);

const JobSchedule = React.lazy(() =>
  import("../../components/job-schedule/JobSchedule")
);

const Prints = React.lazy(() => import("../../components/prints/Prints"));

const ManageUserList = React.lazy(() =>
  import("../../components/user-management/user-list/ManageUserList")
);

const VotingStatistics = React.lazy(() =>
  import("../../components/analytics/voting-statistics/VotingStatistics")
);

const ManageRegressionPlot = React.lazy(() =>
  import("../../components/analytics/regression/ManageRegressionPlot")
);

const loading = <Loading />;

const TheContent = () => {
  const [isLoggedIn, setLoginStatus] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pathArray, setPathArray] = useState([]);

  useEffect(() => {
    fetchData();

    let pathArray = [];
    for (const path in ApplicationPaths) {
      pathArray.push(ApplicationPaths[path]);
    }

    setPathArray(pathArray);
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    await authService
      .isAuthenticated()
      .then(async function (value) {
        value ? setLoginStatus(true) : setLoginStatus(false);
        await authService.isAdmin().then((value) => {
          value ? setIsAdmin(true) : setIsAdmin(false);
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(setIsLoading(false));
  };

  return (
    <main className="c-main">
      {isLoading ? (
        <></>
      ) : (
        <CContainer fluid>
          <Suspense fallback={loading}>
            <Switch>
              <Route exact path="/">
                {isAdmin ? (
                  <Redirect to="/master-list-directory" />
                ) : isLoggedIn ? (
                  <Redirect to="/voting-sheet" />
                ) : (
                  <Login action={LoginActions.Login} />
                )}
              </Route>
              <AuthorizeRoute
                exact
                path="/master-list-directory"
                name="Master List Directory"
                component={ManageListDirectory}
                requiresAdmin={true}
              />
              <AuthorizeRoute
                exact
                path="/master-list-directory/:id"
                name="Master List"
                component={ManageMasterList}
                requiresAdmin={true}
              />
              <AuthorizeRoute
                exact
                path="/master-list-directory/:id/job/:masterListDetailId"
                name="Edit Job"
                component={ManageJobForm}
                requiresAdmin={true}
              />
              <AuthorizeRoute
                exact
                path="/master-list-directory/:id/job"
                name="Add Job"
                component={ManageJobForm}
                requiresAdmin={true}
              />
              <AuthorizeRoute
                exact
                path="/voting-sheet"
                name="Voting Sheet"
                component={JobPreferenceSelection}
                requiresAdmin={false}
              />
              <AuthorizeRoute
                exact
                path="/voting-sheet-review"
                name="Voting Sheet Review"
                component={JobPreferenceReview}
                requiresAdmin={true}
              />
              <AuthorizeRoute
                exact
                path="/voting-sheet-review/:id"
                name="Voting Sheet Review"
                component={JobPreferenceSelection}
                requiresAdmin={true}
              />
              <AuthorizeRoute
                exact
                path="/job-assignment"
                name="Job Assignment"
                component={ManageJobAssignment}
                requiresAdmin={true}
              />
              <AuthorizeRoute
                exact
                path="/job-assignment-schedule"
                name="Job Schedule"
                component={JobSchedule}
                requiresAdmin={true}
              />
              <AuthorizeRoute
                exact
                path="/user-management"
                name="User Management"
                component={ManageUserList}
                requiresAdmin={true}
              />
              <AuthorizeRoute
                exact
                path="/prints"
                name="Prints"
                component={Prints}
                requiresAdmin={false}
              />
              <AuthorizeRoute
                exact
                path="/analytics/voting-statistics"
                name="Voting Statistics"
                component={VotingStatistics}
                requiresAdmin={false}
              />
              <AuthorizeRoute
                exact
                path="/analytics/voting-trends"
                name="Voting Trends"
                component={ManageRegressionPlot}
                requiresAdmin={false}
              />
              {routes.map((route, idx) => {
                return (
                  route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => (
                        <CFade>
                          <route.component {...props} />
                        </CFade>
                      )}
                    />
                  )
                );
              })}

              {pathArray.map((route, index) => {
                return (
                  <Route key={index} path={route} render={() => <Loading />} />
                );
              })}

              {isLoggedIn ? (
                <></>
              ) : (
                <Redirect from="/" to="/authentication/login" />
              )}
            </Switch>
          </Suspense>
        </CContainer>
      )}
    </main>
  );
};

export default React.memo(TheContent);
