import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import { findIndexBefore, sortJobs } from "../../utils/jobUtils";

export default function jobReducer(state = initialState.jobs, action) {
  let jobs = [];
  switch (action.type) {
    case types.CREATE_JOB_SUCCESS:
      jobs = [...state];
      jobs.splice(
        findIndexBefore(action.job.jobName.toUpperCase(), jobs) + 1,
        0,
        action.job
      );
      return jobs;
    case types.UPDATE_JOB_SUCCESS:
      return state.map((job) =>
        job.masterListDetailId === action.job.masterListDetailId
          ? action.job
          : job
      );
    case types.LOAD_JOBS_SUCCESS:
      jobs = sortJobs(action.jobs);
      jobs = jobs.filter(j => j.jobName.toUpperCase() !== "SNOOSE");
      return jobs;
    case types.DELETE_JOB_SUCCESS:
      return state.filter(
        (job) => job.masterListDetailId !== action.job.masterListDetailId
      );
    default:
      return state;
  }
}
