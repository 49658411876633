const _nav = [
  {
    _tag: "CSidebarNavTitle",
    _children: ["Sawbill Work Credit"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Voting Sheet",
    to: "/voting-sheet",
    icon: "cil-balance-scale",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Voting History",
    to: "/voting-history",
    icon: "cil-history",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Prints",
    to: "/prints",
    icon: "cil-print",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Analytics",
    icon: "cil-chart",
    route: "/analytics",
    _children: [
      {
        _tag: "CSidebarNavItem",
        to: "/analytics/voting-statistics",
        name: "Voting Statistics",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/analytics/voting-trends",
        name: "Voting Trends",
      },
    ],
  },
];

export default _nav;
