import React from "react";

const ManageListDirectory = React.lazy(() =>
  import("../components/master-list/list-directory/ManageListDirectory")
);

const ManageMasterList = React.lazy(() =>
  import("../components/master-list/master-list/ManageMasterList")
);
const ManageJobForm = React.lazy(() =>
  import("../components/master-list/job-form/ManageJobForm")
);

const JobPreferenceSelection = React.lazy(() =>
  import("../components/job-preference-selection/JobPreferenceSelection")
);

const JobPreferenceReview = React.lazy(() =>
  import("../components/job-preference-review/JobPreferenceReview")
);

const ManageJobAssignment = React.lazy(() =>
  import("../components/job-assignment/ManageJobAssignment")
);
const JobSchedule = React.lazy(() =>
  import("../components/job-schedule/JobSchedule")
);

const JobPreferenceHistory = React.lazy(() =>
  import("../components/job-preference-history/JobPreferenceHistory")
);

const Prints = React.lazy(() => import("../components/prints/Prints"));

const ManageUserList = React.lazy(() =>
  import("../components/user-management/user-list/ManageUserList")
);

const VotingStatistics = React.lazy(() =>
  import("../components/analytics/voting-statistics/VotingStatistics")
);

const ManageRegressionPlot = React.lazy(() =>
  import("../components/analytics/regression/ManageRegressionPlot")
);

const routes = [
  {
    path: "/master-list-directory/",
    exact: true,
    name: "Master List Directory",
    component: ManageListDirectory,
  },
  {
    path: "/master-list-directory/:id",
    exact: true,
    name: "Master List",
    component: ManageMasterList,
  },
  {
    path: "/master-list-directory/:id/job/:id",
    exact: true,
    name: "Edit Job",
    component: ManageJobForm,
  },
  {
    path: "/voting-sheet",
    exact: true,
    name: "Voting Sheet",
    component: JobPreferenceSelection,
  },
  {
    path: "/voting-sheet-review",
    exact: true,
    name: "Voting Sheet Review",
    component: JobPreferenceReview,
  },
  {
    path: "/voting-sheet-review/:id",
    exact: true,
    component: JobPreferenceSelection,
  },
  {
    path: "/job-assignment",
    exact: true,
    name: "Job Assignment",
    component: ManageJobAssignment,
  },
  {
    path: "/job-assignment-schedule",
    exact: true,
    name: "Job Assignment Schedule",
    component: JobSchedule,
  },
  {
    path: "/voting-history",
    exact: true,
    name: "Voting History",
    component: JobPreferenceHistory,
  },
  {
    path: "/prints",
    exact: true,
    name: "Prints",
    component: Prints,
  },
  {
    path: "/user-management/",
    exact: true,
    name: "User Management",
    component: ManageUserList,
  },
  {
    path: "/analytics/voting-statistics/",
    exact: true,
    name: "Voting Statistics",
    component: VotingStatistics,
  },
  {
    path: "/analytics/voting-trends/",
    exact: true,
    name: "Voting Trends",
    component: ManageRegressionPlot,
  },
];

export default routes;
