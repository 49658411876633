import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function listReducer(state = initialState.list, action) {
  switch (action.type) {
    case types.LOAD_LIST_SUCCESS:
      return action.list;
    case types.CREATE_LIST_SUCCESS:
      return action.list;
    case types.DELETE_LIST_SUCCESS: 
      return action.list;
    default:
      return state;
  }
}
