import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function listReducer(state = initialState.lists, action) {
  let lists = [];
  switch (action.type) {
    case types.CREATE_LIST_SUCCESS:
      lists = [...state, { ...action.list }];
      return lists;
    case types.UPDATE_LIST_SUCCESS:
      return state.map((list) =>
        list.id === action.list.id ? action.list : list
      );
    case types.LOAD_LISTS_SUCCESS:
      return action.lists;
    case types.DELETE_LIST_SUCCESS:
      return state.filter((list) => list.id !== action.list.id);
    default:
      return state;
  }
}
