import React from "react";
import { CDropdownItem } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { ApplicationPaths } from "./ApiAuthorizationConstants";

function LogoutButton() {
  const logoutPath = {
    pathname: `${ApplicationPaths.LogOut}`,
    state: { local: true },
  };

  return (
    <CDropdownItem to={logoutPath}>
      <CIcon name="cil-lock-locked" className="mfe-2" />
      Log Out
    </CDropdownItem>
  );
}

export default LogoutButton;
